<template>
  <div class="text-center">
    <v-dialog hide-overlay v-model="modal" width="700">
      <v-toolbar flat dark color="dark">
        <v-toolbar-title>Detay</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-form>
            <v-card-title class="mt-0"></v-card-title>
            <v-row class="mt-0">
              <v-img
                v-if="model.attributes?.Image"
                class="ma-2"
                :src="`${url}/${model.attributes?.Image}`"
              ></v-img>
            </v-row>
            <v-row class="mt-6"> </v-row>
          </v-form>
          <v-row class="mt-10">
            <v-btn dark @click="$emit('hideDialog')" color="primary">
              Kapat
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import config from "../../public/config.json";

export default {
  computed: {
    url() {
      return config.apiUrl;
    },
  },
  props: { modal: null, model: null },
};
</script>

<style></style>
