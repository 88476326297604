<template>
  <v-app>
    <v-layout justify-center align-center>
      <v-flex shrink>
        <div>
          <v-img
            :src="`${url}/${resim}`"
            max-height="150"
            max-width="150"
          ></v-img>
        </div>
      </v-flex>
    </v-layout>

    <v-main>
      <router-view />
    </v-main>
    <v-footer app padles>
      <v-card class="flex" flat tile>
        <v-card-text class="py-2 white--text text-center">
          <v-btn
            v-if="this.$store.state.firma_bilgisi?.Facebook"
            class="mx-4 white--text"
            icon
            @click="facebookclick"
          >
            <v-icon size="24px"> mdi-facebook </v-icon>
          </v-btn>
          <v-btn
            v-if="this.$store.state.firma_bilgisi?.Instagram"
            class="mx-4 white--text"
            icon
            @click="instaclick"
          >
            <v-icon size="24px"> mdi-instagram </v-icon>
          </v-btn>
          <v-btn
            v-if="this.$store.state.firma_bilgisi?.Gsm"
            @click="wpclick"
            class="mx-4 white--text"
            icon
          >
            <v-icon size="24px"> mdi-whatsapp </v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    gelen_bilgi: null,
    //
  }),
  async mounted() {},
  methods: {
    anasayfa() {
      this.this.$router.push({ name: "" });
    },
    wpclick() {
      window.location.href =
        "https://wa.me/" + this.$store.state.firma_bilgisi?.Gsm;
    },
    facebookclick() {
      window.location.href =
        "https://" + this.$store.state.firma_bilgisi?.Facebook;
    },
    instaclick() {
      window.location.href =
        "https://" + this.$store.state.firma_bilgisi?.Instagram;
    },
  },
  computed: {
    url() {
      return this.$store.state.apiurl;
    },
    resim() {
      return this.$store.state.firma_bilgisi?.Logo;
    },
  },
};
</script>
