import { ApiClient } from "../helpers/api-client";
import config from "../../public/config.json";
import store from "../store"; // Vuex store dosyasını içe aktarın

const apiClient = new ApiClient();

const config_cagir = async () => {
  console.log(config.apiUrl);
  return await apiClient.makeRequest({
    url: `${config.apiUrl}/config.json`,
    method: "GET",
  });
};

const urun_gruplari = async () => {
  console.log("attım", store.state);
  const headers = {
    appkey: store.state.apikey,
    secretkey: store.state.secretkey,
  };

  return await apiClient.makeRequest({
    url: `${store.state.apiurl}/api/product-groups-tr.php`,
    method: "GET",
    headers: headers,
  });
};

const urunler = async (id) => {
  const headers = {
    appkey: store.state.apikey,
    secretkey: store.state.secretkey,
    id: id,
  };
  const response = await apiClient.makeRequest({
    url: `${store.state.apiurl}/api/products-tr.php`,
    method: "GET",
    headers: headers,
  });
  return response.data;
};

const firma_bilgileri = async () => {
  console.log("a",store.state.apiurl);
  const headers = {
    appkey: store.state.apikey,
    secretkey: store.state.secretkey,
  };
  const response = await apiClient.makeRequest({
    url: `${store.state.apiurl}/api/system-info.php`,
    method: "GET",
    headers: headers,
  });
  return response.data;
};

// const urun_gruplari = async () => {
//   return await apiClient.makeRequest({
//     url: `${config.apiUrl}/api/product-groups?populate=*&sort=Sira`,
//     method: "GET",
//   });
// };

// const urunler = async (id) => {
//   const response = await apiClient.makeRequest({
//     url: `${config.apiUrl}/api/products?pagination[limit]=1000&populate=*&filters[product_group][id][$eq]=${id}`,
//     method: "GET",
//   });
//   return response.data;
// };

export { urun_gruplari, urunler, firma_bilgileri, config_cagir };
